import Sortable from 'sortablejs'

const SortableDataTable = {
  bind(el, binding, vnode) {
    if (!Object.prototype.hasOwnProperty.call(binding, 'value') || binding.value) {
      const options = {
        animation: 150,
        ghostClass: 'bg-gradient-primary',
        delay: 100,
        onUpdate: event => vnode.child.$emit('sorted', event),
      }

      Sortable.create(el.getElementsByTagName('tbody')[0], options)
    }
  },
}

export default SortableDataTable
